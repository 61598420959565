import * as React from "react"
import Layout from "../components/Layout";
import { graphql } from 'gatsby';
import MyPortableText from "../components/MyPortableText";
import SEO from "../components/SEO";

export const query = graphql`
    {
    allSanitySettings{
        edges {
      node {
        addresszeile1
        addresszeile2
        addresszeile3
        kontaktemail
        telefon
        instalink
        fblink
      }
    }
    }
    allSanityImpressum{
        edges {
      node {
        _rawImpressum
        _rawDatenschutz
      }
    }
    }
    allSanityStartseite {
    edges {
      node {
      livevid
      albumcovers {
          _key
        }
      }
      }
      }
  }
`

const Impressum = ({data}) => {

    const settings = data.allSanityImpressum.edges[0].node;
    const page = data.allSanityStartseite.edges[0].node;

    return (
        <Layout impressum={true} settings={data.allSanitySettings.edges[0].node} live={page.livevid ? true : false} repertoire={page.albumcovers ? true : false}>
            <SEO title={"Impressum | Fleur de Nuit"}/>
            <div className="impressum__outer">
                <div className="impressum__inner introtext">
                        <MyPortableText value={settings._rawImpressum}></MyPortableText>
                    <br/>
                        <MyPortableText value={settings._rawDatenschutz}></MyPortableText>
                </div>
            </div>
        </Layout>
    )
}

export default Impressum

